import React from "react";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { RiFacebookLine, RiInstagramLine, RiTwitterLine, RiLinkedinBoxFill} from 'react-icons/ri';
import vinti4 from "../../assets/all-images/vinti4.jpg";
import masterCard from "../../assets/all-images/mastercard.jpg";
import visa from "../../assets/all-images/visa.jpg";
import chevroletLogo from "../../assets/all-images/chevrolet.svg";
import hondaLogo from "../../assets/all-images/honda.svg";
import jeepLogo from "../../assets/all-images/jeep.svg";
import gmcLogo from "../../assets/all-images/gmc.svg";
import mitsubishiLogo from "../../assets/all-images/mitsubishi.svg";
import hyundayLogo from "../../assets/all-images/Hyunday.svg";
import fordLogo from "../../assets/all-images/ford.svg";
import toyotaLogo from "../../assets/all-images/toyota.svg";
import infinitiLogo from "../../assets/all-images/infiniti.svg";
import "../../styles/footer.css";


const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const translations = useSelector(
    (state) => state.translation.translations[state.translation.selectedLanguage].footer
  );

  const copyrightText = translations.copyRight.text;
  const translatedText = copyrightText.replace("{year}", year);


  const quickLinks = [
    {
      path: "/about",
      display: translations.quickLinks.about,
    },
    {
      path: "/service",
      display: translations.quickLinks.services,
    },
    {
      path: "/partners",
      display: translations.quickLinks.partners,
    },
    {
      path: "/contact",
      display: translations.quickLinks.contact,
    },
  ];

  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="4" md="4" sm="12">
            <div className="logo footer__logo">
              <h1>
                <Link to="/home" className="d-flex align-items-center gap-2"  onClick={handleLinkClick}>
                  <i className="ri-car-line"></i>
                  <span>
                    CV Rental
                  </span>
                </Link>
              </h1>
            </div>
            <p className="footer__logo-content">
              {translations.logo.description}
            </p>
          </Col>

          <Col lg="2" md="4" sm="12">
            <div className="mb-4">
              <h5 className="footer__link-title">{translations.navigation.title}</h5>
              <ListGroup>
                {quickLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link onClick={handleLinkClick} to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>

          <Col lg="3" md="4" sm="12">
            <div className="mb-4">
              <h5 className="footer__link-title">{translations.links.title}</h5>
              <ListGroup>
                <ListGroupItem className="p-0 mt-3 quick__link">
                  <Link to="/reservation-policy" onClick={handleLinkClick}>{translations.links.reservationPolicy}</Link>
                </ListGroupItem>
                <ListGroupItem className="p-0 mt-3 quick__link">
                  <Link to="/rates-and-fees-policy" onClick={handleLinkClick}>{translations.links.ratesAndFeesPolicy}</Link>
                </ListGroupItem>
                <ListGroupItem className="p-0 mt-3 quick__link">
                  <Link to="/cancellation-refund-policy" onClick={handleLinkClick}>{translations.links.cancellationRefundPolicy}</Link>
                </ListGroupItem>
                <ListGroupItem className="p-0 mt-3 quick__link">
                  <Link to="/liability-damage-policy" onClick={handleLinkClick}>{translations.links.liabilityDamagePolicy}</Link>
                </ListGroupItem>
                <ListGroupItem className="p-0 mt-3 quick__link">
                  <Link to="/usage-policy" onClick={handleLinkClick}>{translations.links.usagePolicy}</Link>
                </ListGroupItem>
                <ListGroupItem className="p-0 mt-3 quick__link">
                  <Link to="/privacy-policy" onClick={handleLinkClick}>{translations.links.privacyPolicy}</Link>
                </ListGroupItem>
                <ListGroupItem className="p-0 mt-3 quick__link">
                  <Link to="/cookies-policy" onClick={handleLinkClick}>{translations.links.cookiesPolicy}</Link>
                </ListGroupItem>
              </ListGroup>
            </div>
          </Col>


          <Col lg="3" md="4" sm="12">
            <div className="mb-4">
              <h5 className="footer__link-title">{translations.followUs.title}</h5>
              <div className="social-media-icons">
                <a href="https://www.facebook.com/sua_pagina" target="_blank" rel="noopener noreferrer">
                  <RiFacebookLine className="social-media-icon facebook-icon" style={{ fontSize: '23px' }}/>
                </a>
                <a href="https://www.instagram.com/cv_rental" target="_blank" rel="noopener noreferrer">
                  <RiInstagramLine className="social-media-icon instagram-icon" style={{ fontSize: '23px' }}/>
                </a>
                <a href="https://www.linkedin.com/sua_pagina" target="_blank" rel="noopener noreferrer">
                  <RiLinkedinBoxFill className="social-media-icon linkedin-icon" style={{ fontSize: '23px' }}/>
                </a>
                <a href="https://www.twitter.com/sua_pagina" target="_blank" rel="noopener noreferrer">
                    <RiTwitterLine className="social-media-icon twitter-icon" style={{ fontSize: '23px' }} />
                </a>
              </div>
            </div>
            <div className="mb-4">
              <h5 className="footer__link-title">{translations.paymentMethods.title}</h5>
              <div className="payment-methods">
                <img src={vinti4} alt="Vinti4" className="payment-method" />
                <img src={visa} alt="Visa" className="payment-method" />
                <img src={masterCard} alt="MasterCard" className="payment-method" />
              </div>
            </div>
            <div className="mb-4">
              <h5 className="footer__link-title">{translations.carBrands.title}</h5>
              <div className="car-brands">
                <img src={chevroletLogo} alt="Chevrolet" className="car-brand" />
                <img src={hondaLogo} alt="Honda" className="car-brand" />
                <img src={gmcLogo} alt="Gmc" className="car-brand" />
              </div>
              <div className="car-brands">
                <img src={jeepLogo} alt="Jeep" className="car-brand" />
                <img src={mitsubishiLogo} alt="Mitsubishi" className="car-brand" />
                <img src={fordLogo} alt="Ford" className="car-brand" />
              </div>
              <div className="car-brands">
                <img src={toyotaLogo} alt="Toyota" className="car-brand" />
                <img src={hyundayLogo} alt="Hyunday" className="car-brand" />
                <img src={infinitiLogo} alt="Infiniti" className="car-brand" />
              </div>
            </div>
          </Col>
      
          <Col lg="12">
            <div className="footer__bottom d-flex align-items-center justify-content-center gap-1 pt-4">
              <p className="section__description text-center flex-grow-1">
                <i className="ri-copyright-line"></i>{translatedText}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
