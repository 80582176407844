import { createSlice } from '@reduxjs/toolkit';

const transformFacebookData = (data) => {
  return {
    provider: 'facebook',
    name: data.name,
    picture: data.picture.data.url,
  };
};

const initialFacebookState = {
  user: null,
};

const facebookSlice = createSlice({
  name: 'facebook',
  initialState: initialFacebookState,
  reducers: {
    setFacebookUser: (state, action) => {
      const transformedData = transformFacebookData(action.payload);
      state.user = transformedData;
    },
    logoutFacebookUser: (state) => {
      state.user = null;
    },
  },
});


export const { setFacebookUser, logoutFacebookUser } = facebookSlice.actions;

export default facebookSlice.reducer;
