import React from "react";
import Slider from "react-slick";

import "../../styles/partners.css";

import logo1 from "../../assets/all-images/best_rental_logo2.jpg";
import logo2 from "../../assets/all-images/best_rental_logo2.jpg";
import logo3 from "../../assets/all-images/best_rental_logo2.jpg";
import logo4 from "../../assets/all-images/best_rental_logo2.jpg";


const Partners = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
    <div className="testimonial py-4 px-3">
      <img src={logo1} alt="" className="w-100" />
    </div>

    <div className="testimonial py-4 px-3">
      <img src={logo2} alt="" className="w-100" />
    </div>

    <div className="testimonial py-4 px-3">
      <img src={logo3} alt="" className="w-100" />
    </div>

    <div className="testimonial py-4 px-3">
      <img src={logo4} alt="" className="w-100" />
    </div>
  </Slider>
  );
};

export default Partners;
