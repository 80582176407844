// googleSlice.js

import { createSlice } from '@reduxjs/toolkit';

const transformGoogleData = (data) => {
  return {
    provider: 'google',
    name: data.name,
    picture: data.picture,
  };
};

const initialGoogleState = {
    user:  null,
  };

const googleSlice = createSlice({
  name: 'google',
  initialState: initialGoogleState,
  reducers: {
    setGoogleUser: (state, action) => {
      const transformedData = transformGoogleData(action.payload);
      state.user = transformedData;
    },
    logoutGoogleUser: (state) => {
      state.user = null;
    },
  },
});

export const { setGoogleUser, logoutGoogleUser } = googleSlice.actions;

export default googleSlice.reducer;
