import React, { useEffect }  from "react";
import { Container, Row, Col } from "reactstrap";
import "../../styles/about-section.css";
import aboutImg from "../../assets/all-images/cars-img/gmc-terrain.png";
import ScrollReveal from 'scrollreveal';
import { useSelector } from 'react-redux';



const AboutSection = ({ aboutClass }) => {

  const translations = useSelector(
    (state) => state.translation.translations[state.translation.selectedLanguage].about
  );
  
  useEffect(() => {
    ScrollReveal().reveal('.animate', {
      delay: 200, // atraso em milissegundos antes da animação começar
      distance: '20px', // distância de deslocamento do elemento
      origin: 'left', // ponto de origem da animação
      duration: 1300, // duração da animação em milissegundos
      easing: 'linear', // função de easing para a animação
      mobile: true // permite que as animações sejam executadas em dispositivos móveis
    });
    ScrollReveal().reveal('.animateImg', {
      delay: 200, // atraso em milissegundos antes da animação começar
      distance: '20px', // distância de deslocamento do elemento
      origin: 'right', // ponto de origem da animação
      duration: 1300, // duração da animação em milissegundos
      easing: 'linear', // função de easing para a animação
      mobile: true // permite que as animações sejam executadas em dispositivos móveis
    });
  }, []);

  return (
    <section
      className="about__section"
      style={
        aboutClass === "aboutPage"
          ? { marginTop: "0px" }
          : { marginTop: "20px" }
      }
    >
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="animate about__section-content">
              <h4 className="section__subtitle">{translations.subtitle}</h4>
              <h2 className="section__title">{translations.title}</h2>
              <p className="section__description">
              {translations.description}
              </p>

              <div className="animate about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i className="ri-checkbox-circle-line"></i> {translations.services.service1}
                </p>

                <p className="animate section__description d-flex align-items-center gap-2">
                  <i className="ri-checkbox-circle-line"></i> {translations.services.service2}
                </p>
              </div>

              <div className="animate about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i className="ri-checkbox-circle-line"></i>  {translations.services.service3}
                </p>

                <p className="animate section__description d-flex align-items-center gap-2">
                  <i className="ri-checkbox-circle-line"></i>  {translations.services.service4}
                </p>
              </div>
            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="animateImg about__img">
              <img src={aboutImg} alt="" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutSection;
