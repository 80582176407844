import React, { useState } from 'react';
import { Container, Row } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import apiUtils from '../apiUtils';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { LoginSocialGoogle } from 'reactjs-social-login';
import { GoogleLoginButton } from 'react-social-login-buttons';
import { FacebookLoginButton } from 'react-social-login-buttons';
import ReCAPTCHA from 'react-google-recaptcha';
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { setLoggedIn } from '../redux/authSlice';
import { setFacebookUser } from '../redux/facebookSlice';
import { setGoogleUser } from '../redux/googleSlice';
import { setLoginUser } from '../redux/loginSlice';
import '../styles/login-form.css';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fbAppID = process.env.REACT_APP_FB_APP_ID;
  const ggAppID = process.env.REACT_APP_GG_APP_ID;
  const recaptchaKey = process.env.REACT_APP_REACAPTCHA_SITE_KEY;

  const [recaptchaFilled, setRecaptchaFilled] = useState(false);

  const translations = useSelector(
    (state) => state.translation.translations[state.translation.selectedLanguage].login
  );

  const language = useSelector(state => state.translation.selectedLanguage);


  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email(translations.emailValidation.invalidEmail).required(translations.emailValidation.requiredEmail),
      password: Yup.string().required(translations.passwordValidation.requiredPassword),
      recaptchaToken: Yup.string().required(translations.recaptchaValidation.requiredRecaptcha),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (!recaptchaFilled) {
        return;
      }

      try {
        const response = await apiUtils.login(values);

        console.log(response.data);


        localStorage.setItem('token', response.data.token);
        //localStorage.setItem('user', JSON.stringify(response.data));


        resetForm();
        dispatch(setLoggedIn());
        dispatch(setLoginUser(response.data));
        navigate('/home');

        //toast.success('Login bem-sucedido! Bem-vindo!');
      } catch (error) {
        console.log(error.response.data);
        toast.error(translations.invalidCredentialsError, {
          duration: 5000,
          position: 'top-center',
        }); // Exibir mensagem de erro
      }
    },
  });

  

  return (
    <Container>
      <Row>
        <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
          <div className="card border-0 rounded-3 my-5">
            <div className="card-body p-4 p-sm-5">
              <h3 className="card-title text-center mb-4 fw-light">{translations.cardTitle}</h3>
              <Toaster containerStyle={{ top: 80 }} />
              <form onSubmit={formik.handleSubmit}>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className={`form-control ${
                      formik.touched.email && formik.errors.email ? 'is-invalid' : ''
                    }`}
                    id="floatingInput"
                    placeholder="name@example.com"
                    {...formik.getFieldProps('email')}
                  />
                  <label htmlFor="floatingInput">{translations.emailLabel}</label>
                  {formik.touched.email && formik.errors.email && (
                    <div className="invalid-feedback">{formik.errors.email}</div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className={`form-control ${
                      formik.touched.password && formik.errors.password ? 'is-invalid' : ''
                    }`}
                    id="floatingPassword"
                    placeholder="Password"
                    {...formik.getFieldProps('password')}
                  />
                  <label htmlFor="floatingPassword">{translations.passwordLabel}</label>
                  {formik.touched.password && formik.errors.password && (
                    <div className="invalid-feedback">{formik.errors.password}</div>
                  )}
                </div>
                <div className={`mb-3 ${formik.errors.recaptchaToken && formik.touched.recaptchaToken ? 'is-invalid' : ''}`}>
                  <ReCAPTCHA
                    sitekey={recaptchaKey}
                    onChange={(token) => {
                      formik.setFieldValue('recaptchaToken', token);
                      setRecaptchaFilled(true);
                    }}
                    hl={language}
                  />
                  {formik.errors.recaptchaToken && formik.touched.recaptchaToken && (
                    <div className="invalid-feedback">{formik.errors.recaptchaToken}</div>
                  )}
                </div>

                <div className="d-flex justify-content-between align-items-center mb-3">
                  <Link to="/forget-password" className="forgot-password-link">
                    {translations.forgotPasswordLink}
                  </Link>
                </div>

                <div className="d-grid">
                  <button className="btn btn-login" type="submit">
                    <i className="me-2 ri-login-circle-line"></i> {translations.loginButton}
                  </button>
                </div>
                <hr className="my-4" />
                <div className="d-grid mb-2">
                  <LoginSocialGoogle
                    client_id={ggAppID}
                    scope="openid profile email"
                    discoveryDocs="claims_supported"
                    access_type="offline"
                    onResolve={({ data }) => {
                      console.log(data);
                      const { access_token, name, email } = data;
                      const requestData = {
                        token: access_token,
                        name: name,
                        email: email,
                      };
                      dispatch(setGoogleUser(data));

                      apiUtils
                        .post('login/google', requestData)
                        .then((response) => {
                          console.log(response.data);
                          localStorage.setItem('token', response.data.token);

                          dispatch(setLoggedIn());
                          navigate('/home');
                          toast.success('Login bem-sucedido! Bem-vindo!');
                        })
                        .catch((error) => {
                          console.log(error.response.data);
                          toast.error('Credenciais inválidas. Verifique seu email e password.');
                        });
                    }}
                    onReject={(err) => {
                      console.log(err);
                    }}
                  >
                    <GoogleLoginButton  text={translations.googleLoginButton} />
                  </LoginSocialGoogle>
                </div>
                <div className="d-grid">
                  <LoginSocialFacebook
                    appId={fbAppID}
                    fields="id,email,first_name,last_name,middle_name,name,name_format,picture,short_name,gender"
                    scope="email"
                    onResolve={(response) => {
                      console.log(response);
                      const { accessToken, name, email, id } = response.data;
                      const requestData = {
                        token: accessToken,
                        name: name,
                        email: email,
                        id: id,
                      };
                      dispatch(setFacebookUser(response.data));


                      apiUtils
                        .post('login/facebook', requestData)
                        .then((response) => {
                          console.log(response.data);
                          localStorage.setItem('token', response.data.token);

                          dispatch(setLoggedIn());
                          navigate('/home');
                          toast.success('Login bem-sucedido! Bem-vindo!');
                        })
                        .catch((error) => {
                          console.log(error.response.data);
                          toast.error('Credenciais inválidas. Verifique seu email e password.');
                        });
                    }}
                    onReject={(error) => {
                      console.log(error);
                    }}
                  >
                    <FacebookLoginButton text={translations.facebookLoginButton}  />
                  </LoginSocialFacebook>
                </div>
                <div className="text-center mt-3">
                  {translations.noAccountText}{' '}
                  <Link to="/register" className="create-account-link">
                    {translations.createAccountLink}
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default Login;
