import { createSlice } from '@reduxjs/toolkit';

const transformCvrentalData = (data) => {
  return {
    provider: 'cvrental',
    name: data.name,
    picture: null,
  };
};

const initialLoginState = {
  
  user: null,
};

const loginSlice = createSlice({
  name: 'login',
  initialState: initialLoginState,
  reducers: {
    setLoginUser: (state, action) => {
      const transformedData = transformCvrentalData(action.payload);
      state.user = transformedData;
    },
    clearLoginUser: (state) => {
      state.user = null;
    },
  },
});

export const { setLoginUser, clearLoginUser } = loginSlice.actions;

export default loginSlice.reducer;
