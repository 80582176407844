import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './authSlice';
import facebookReducer from './facebookSlice';
import googleReducer from './googleSlice';
import loginSlice from './loginSlice';
import translationReducer from './translationSlice';

import thunk from 'redux-thunk';

const persistConfig = {
  key: 'auth', // Change the key for authReducer
  storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);

const persistFacebookConfig = {
  key: 'facebook', // Change the key for facebookReducer
  storage,
};

const persistedFacebookReducer = persistReducer(persistFacebookConfig, facebookReducer);

const persistGoogleConfig = {
  key: 'google', // Change the key for googleReducer
  storage,
};

const persistedGoogleReducer = persistReducer(persistGoogleConfig, googleReducer);

const persistLoginConfig = {
  key: 'login', // Change the key for loginSlice
  storage,
};

const persistedLoginReducer = persistReducer(persistLoginConfig, loginSlice);

const persistTranslationConfig = {
  key: 'translation', // Change the key for translationReducer
  storage,
}

const persistedTranslationReducer = persistReducer(persistTranslationConfig, translationReducer);



export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    facebook: persistedFacebookReducer,
    google: persistedGoogleReducer,
    login: persistedLoginReducer,
    translation: persistedTranslationReducer,
  },
  middleware: [thunk],
});

export const persistor = persistStore(store);
