import React, { useState, useEffect } from 'react';
import { RiArrowUpLine } from 'react-icons/ri';
import '../../styles/ScrollToTopArrow.css';

const ScrollToTopArrow = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollPosition > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className={`scroll-to-top-arrow ${isVisible ? 'visible' : ''}`} onClick={scrollToTop}>
      <RiArrowUpLine className="scroll-to-top-arrow__icon" />
    </div>
  );
};

export default ScrollToTopArrow;
