import React from "react";
import { useSelector } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Input } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import { Formik } from "formik";
import * as Yup from "yup";

import "../styles/contact.css";

const socialLinks = [
  {
    url: "https://www.facebook.com/Cvrental",
    icon: "ri-facebook-line",
  },
  {
    url: "https://www.instagram.com/cv_rental/",
    icon: "ri-instagram-line",
  },
  {
    url: "#",
    icon: "ri-linkedin-line",
  },
  {
    url: "#",
    icon: "ri-twitter-line",
  }
];



const Contact = () => {
  const initialValues = {
    name: "",
    email: "",
    message: "",
  };

  const translations = useSelector(
    (state) => state.translation.translations[state.translation.selectedLanguage].contact
  );


  const validationSchema = Yup.object().shape({
    name: Yup.string().required(translations.formValidation.nameRequired),
    email: Yup.string()
      .email(translations.formValidation.emailInvalid)
      .required(translations.formValidation.emailRequired),
    message: Yup.string().required(translations.formValidation.messageRequired),
  });

  const onSubmit = (values) => {
    console.log(values);
  };

  
  return (
    <Helmet title="Contact">
      <CommonSection title={translations.title}/>
      <section>
        <Container>
          <Row>
            <Col lg="7" md="7">
              <h6 className="fw-bold mb-4">{translations.formTitle}</h6>

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                  <Form onSubmit={handleSubmit} noValidate>
                    <FormGroup className="contact__form">
                      <Input
                        placeholder={translations.namePlaceholder}
                        type="text"
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        className={touched.name && errors.name ? "is-invalid" : ""}
                      />
                      {touched.name && errors.name ? (
                        <div className="invalid-feedback">{errors.name}</div>
                      ) : null}
                    </FormGroup>
                    <FormGroup className="contact__form">
                      <Input
                        placeholder={translations.emailPlaceholder}
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        className={touched.email && errors.email ? "is-invalid" : ""}
                      />
                      {touched.email && errors.email ? (
                        <div className="invalid-feedback">{errors.email}</div>
                      ) : null}
                    </FormGroup>
                    <FormGroup className="contact__form">
                      <textarea
                        rows="5"
                        placeholder={translations.messagePlaceholder}
                        name="message"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.message}
                        className={`textarea ${touched.message && errors.message ? "is-invalid form-control" : ""}`} // adicionando a classe CSS "textarea" e mantendo a lógica de validação existente
                        ></textarea>
                      {touched.message && errors.message ? (
                        <div className="invalid-feedback">{errors.message}</div>
                      ) : null}
                    </FormGroup>

                    <button className=" contact__btn" type="submit">
                      {translations.submitButton}
                    </button>
                  </Form>
                )}
              </Formik>
            </Col>

            <Col lg="5" md="5">
              <div className="contact__info">
                <h6 className="fw-bold">{translations.contactInfoTitle}</h6>
                <p className="section__description mb-0">
                  Praia, Cabo Verde
                </p>
                <div className=" d-flex align-items-center gap-2">
                  <h6 className="fs-6 mb-0">{translations.phoneTitle}</h6>
                  <p className="section__description mb-0">+238 5825090</p>
                </div>

                <div className=" d-flex align-items-center gap-2">
                  <h6 className="mb-0 fs-6">Email:</h6>
                  <p className="section__description mb-0">info@cvrental.cv</p>
                </div>

                <h6 className="fw-bold mt-4">{translations.followUsTitle}</h6>

                <div className=" d-flex align-items-center gap-4 mt-3">
                  {socialLinks.map((item, index) => (
                     <a 
                     href={item.url} 
                     key={index} 
                     target="_blank"
                     className="social__link-icon"
                     rel="noopener noreferrer"
                   >
                     <i className={item.icon}></i>
                   </a>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Contact;
