import React from 'react';
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import { Container } from "reactstrap";
import "../styles/privacy-policy.css"

const PrivacyPolicy = () => {
    
  return (
    <Helmet title="Política de Privacidade">
    <CommonSection title="Política de Privacidade" />
    <Container>
    < div className="privacy-policy">
      <h2>Política de Privacidade</h2>
      <p>
        A CVRental valoriza e respeita a sua privacidade. Esta Política de Privacidade descreve como coletamos, usamos e protegemos suas informações pessoais quando você utiliza nosso website.
      </p>
      <h3>Coleta de Informações</h3>
      <p>
        Coletamos informações pessoais fornecidas por você voluntariamente, como nome, endereço de e-mail, número de telefone, entre outros, quando você preenche formulários em nosso site. Além disso, podemos coletar automaticamente informações não pessoais, como endereço IP, tipo de navegador, data e hora de acesso, páginas visitadas, entre outros, por meio do uso de cookies e tecnologias similares.
      </p>
      <h3>Uso das Informações</h3>
      <p>
        Utilizamos suas informações pessoais para fornecer os serviços solicitados, processar pagamentos, responder a consultas e fornecer suporte ao cliente. Além disso, podemos utilizar informações não pessoais para melhorar nosso website, analisar tendências de uso e personalizar a experiência do utilizador.
      </p>
      <h3>Proteção das Informações</h3>
      <p>
        Implementamos medidas de segurança para proteger suas informações contra acesso não autorizado, uso indevido ou divulgação. No entanto, é importante lembrar que nenhum método de transmissão pela internet ou armazenamento eletrônico é 100% seguro, e não podemos garantir a segurança absoluta das informações fornecidas.
      </p>
      <h3>Compartilhamento de Informações</h3>
      <p>
        Não vendemos, alugamos ou compartilhamos suas informações pessoais com terceiros, exceto quando necessário para fornecer os serviços solicitados por você. Podemos compartilhar informações não pessoais de forma agregada e anonimizada para fins de análise e marketing.
      </p>
      <h3>Links Externos</h3>
      <p>
        Nosso site pode conter links para sites de terceiros. Esta Política de Privacidade se aplica apenas ao nosso website, portanto, recomendamos que você revise as políticas de privacidade de quaisquer sites visitados por meio de links externos.
      </p>
      <h3>Alterações na Política de Privacidade</h3>
      <p>
        Reservamo-nos o direito de modificar esta Política de Privacidade a qualquer momento. Quaisquer alterações serão publicadas em nosso site. É responsabilidade do utilizador revisar periodicamente esta política para estar ciente das atualizações.
      </p>
      <p>
        Ao utilizar nosso website, você concorda com os termos desta Política de Privacidade. Se tiver alguma dúvida ou preocupação sobre nossas práticas de privacidade, entre em contato connosco.
      </p>
    </div>
    </Container>
    </Helmet>
  );
};

export default PrivacyPolicy;
