import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import { useParams } from "react-router-dom";
import BookingForm from "../components/UI/BookingForm";
import PaymentMethod from "../components/UI/PaymentMethod";
import "../styles/loader.css"; 

const CarDetails = () => {
  const { slug } = useParams();
  const [carData, setCarData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  

  useEffect(() => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const SINGLE_CAR   = process.env.REACT_APP_SINGLE_CAR;

    const fetchCarData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${SINGLE_CAR}${slug}`);
        const resposta = await response.json();
        setCarData(resposta.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching car data:", error);
        setIsLoading(false);
      }
    };

    if (isLoading) {
      fetchCarData();
    }
  }, [slug, isLoading]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [carData]);

  if (isLoading) {
    return  <div className="loader">
              <div className="loader__spinner"></div>
            </div>; // Show a loading indicator while fetching the data
  }

  return (
    <Helmet title={carData.carName}>
      <section>
        <Container>
          <Row>
            <Col lg="6">
              <img src={carData.imgUrl} alt="" className="w-100" />
            </Col>

            <Col lg="6">
              <div className="car__info">
                <h2 className="section__title">{carData.carName}</h2>

                <div className=" d-flex align-items-center gap-5 mb-4 mt-3">
                  <h6 className="rent__price fw-bold fs-4">
                    {carData.price}$00 / Dia
                  </h6>

                  <span className=" d-flex align-items-center gap-2">
                    <span style={{ color: "#FFC300" }}>
                      <i className="ri-star-s-fill"></i>
                      <i className="ri-star-s-fill"></i>
                      <i className="ri-star-s-fill"></i>
                      <i className="ri-star-s-fill"></i>
                      <i className="ri-star-s-fill"></i>
                    </span>
                    ({carData.rating} ratings)
                  </span>
                </div>

                <p className="section__description">
                  {carData.description}
                </p>

                <div
                  className=" d-flex align-items-center mt-3"
                  style={{ columnGap: "4rem" }}
                >
                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      className="ri-roadster-line"
                      style={{ color: "#FFC300" }}
                    ></i>{" "}
                    {carData.year}
                  </span>

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      className="ri-settings-2-line"
                      style={{ color: "#FFC300" }}
                    ></i>{" "}
                    {carData.transmission}
                  </span>

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      className="ri-timer-flash-line"
                      style={{ color: "#FFC300" }}
                    ></i>{" "}
                    {carData.speed}
                  </span>
                </div>

                <div
                  className=" d-flex align-items-center mt-3"
                  style={{ columnGap: "2.8rem" }}
                >
                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i className="ri-map-pin-line" style={{ color: "#FFC300" }}></i>{" "}
                    {carData.gps}
                  </span>

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      className="ri-wheelchair-line"
                      style={{ color: "#FFC300" }}
                    ></i>{" "}
                    {carData.seatType}
                  </span>

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      className="ri-building-2-line"
                      style={{ color: "#FFC300" }}
                    ></i>{" "}
                    {carData.brand}
                  </span>
                </div>
              </div>
            </Col>

            <Col lg="7" className="mt-5">
              <div className="booking-info mt-5">
                <h5 className="mb-4 fw-bold ">Informações da Reserva</h5>
                <BookingForm />
              </div>
            </Col>

            <Col lg="5" className="mt-5">
              <div className="payment__info mt-5">
                <h5 className="mb-4 fw-bold ">Informações do Pagamento</h5>
                <PaymentMethod />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default CarDetails;
