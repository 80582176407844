import {React, useEffect, useState} from 'react';
import { Container, Row } from 'reactstrap';
import { Link, useNavigate  } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import apiUtils from '../apiUtils';
import toast, { Toaster } from 'react-hot-toast';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSelector } from 'react-redux';

import '../styles/login-form.css';

const Register = () => {
const navigate = useNavigate();

  const recaptchaKey = process.env.REACT_APP_REACAPTCHA_SITE_KEY;

  const [recaptchaFilled, setRecaptchaFilled] = useState(false);

  useEffect(() => {
    const success = sessionStorage.getItem('success');
    const error = sessionStorage.getItem('error');

    if (success) {
      toast.success(success);
      sessionStorage.removeItem('success');
    }

    if (error) {
      toast.error(error);
      sessionStorage.removeItem('error');
    }
  }, []);

  const translations = useSelector(
    (state) => state.translation.translations[state.translation.selectedLanguage].register
  );

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      recaptchaToken: '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required(translations.validationErrors.nameRequired),
      email: Yup.string().email(translations.validationErrors.emailInvalid).required(translations.validationErrors.emailRequired),
      recaptchaToken: Yup.string().required(translations.validationErrors.recaptchaTokenRequired),
      password: Yup.string().required(translations.validationErrors.passwordRequired),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], translations.validationErrors.confirmPasswordMismatch)
        .required(translations.validationErrors.confirmPasswordRequired)
    }),
    onSubmit: async (values) => {

      if (!recaptchaFilled) {
        return;
      }

      try {
        const response = await apiUtils.post('/register', values);
        console.log(response.data);
    
        if (response.data.message) {
          toast.success(response.data.message);
          sessionStorage.setItem('success', response.data.message);
          navigate('/login');
        }
      } catch (error) {
        console.log(error.response.data);
    
        if (error.response && error.response.data) {
          const errorMessage = error.response.data.message || 'Erro ao realizar o registro';
          let fieldErrors = {};
    
          if (error.response.data.errors) {
            fieldErrors = error.response.data.errors;
          }
    
          // Concatena os erros de validação em uma única string
          let validationErrorMessage = '';
          for (const key in fieldErrors) {
            if (fieldErrors.hasOwnProperty(key)) {
              validationErrorMessage += `${fieldErrors[key][0]} `;
            }
          }
    
          const finalErrorMessage = validationErrorMessage || errorMessage;
          toast.error(finalErrorMessage);
        }
      }
    },
    
  });

  return (
    <Container>
      <Row>
        <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
          <div className="card border-0 rounded-3 my-5">
            <div className="card-body p-4 p-sm-5">
              <h3 className="card-title text-center mb-4 fw-light">{translations.title}</h3>
              <Toaster containerStyle={{
                  top: 80
                }}/> 
              <form onSubmit={formik.handleSubmit}>
                <div className="form-floating mb-3">
                  <input
                    type="name"
                    className={`form-control form-control-sm ${
                      formik.touched.name && formik.errors.name ? 'is-invalid' : ''
                    }`}
                    id="floatingName"
                    placeholder="Nome"
                    {...formik.getFieldProps('name')}
                  />
                  <label htmlFor="floatingName">{translations.name}</label>
                  {formik.touched.name && formik.errors.name && (
                    <div className="invalid-feedback">{formik.errors.name}</div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className={`form-control form-control-sm ${
                      formik.touched.email && formik.errors.email ? 'is-invalid' : ''
                    }`}
                    id="floatingInput"
                    placeholder="name@example.com"
                    {...formik.getFieldProps('email')}
                  />
                  <label htmlFor="floatingInput">{translations.email}</label>
                  {formik.touched.email && formik.errors.email && (
                    <div className="invalid-feedback">{formik.errors.email}</div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className={`form-control ${
                      formik.touched.password && formik.errors.password ? 'is-invalid' : ''
                    }`}
                    id="floatingPassword"
                    placeholder="Password"
                    {...formik.getFieldProps('password')}
                  />
                  <label htmlFor="floatingPassword">{translations.password}</label>
                  {formik.touched.password && formik.errors.password && (
                    <div className="invalid-feedback">{formik.errors.password}</div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className={`form-control ${
                      formik.touched.confirmPassword && formik.errors.confirmPassword ? 'is-invalid' : ''
                    }`}
                    id="floatingConfirmarPassword"
                    placeholder="Confirmar Password"
                    {...formik.getFieldProps('confirmPassword')}
                  />
                  <label htmlFor="floatingConfirmarPassword">{translations.confirmPassword}</label>
                  {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                    <div className="invalid-feedback">{formik.errors.confirmPassword}</div>
                  )}
                </div>
                <div className={`mb-3 ${formik.errors.recaptchaToken && formik.touched.recaptchaToken ? 'is-invalid' : ''}`}>
                  <ReCAPTCHA
                    sitekey={recaptchaKey}
                    onChange={(token) => {
                      formik.setFieldValue('recaptchaToken', token);
                      setRecaptchaFilled(true);
                    }}
                  />
                  {formik.errors.recaptchaToken && formik.touched.recaptchaToken && (
                    <div className="invalid-feedback">{formik.errors.recaptchaToken}</div>
                  )}
                </div>
                <div className="d-grid">
                  <button className="btn btn-login" type="submit">
                    <i className="me-2 ri-login-circle-line"></i> {translations.submit}
                  </button>
                </div>
              </form>
              <div className="terms-of-service">
                {translations.termsAndConditions}{' '}
                <Link to="/terms-of-service">{translations.termsLink}</Link> e {' '}
                <Link to="/privacy-policy">{translations.privacyLink}</Link>.
              </div>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default Register;
