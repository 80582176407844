import React, { useState } from "react";
import { Col, FormGroup, Label, Input, Card, CardBody } from "reactstrap";
import CarItem from "./CarItem";
import '../../styles/car-list.css';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';




const CarList = ({ carData }) => {
  const [priceFilter, setPriceFilter] = useState([]);
  const [transmissionFilter, setTransmissionFilter] = useState([]);
  const [supplierFilter, setSupplierFilter] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [fuelFilter, setFuelFilter] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  `;


  const handlePriceFilterChange = (event) => {
    setIsLoading(true);
    const priceValue = event.target.value;
    if (priceFilter.includes(priceValue)) {
      setPriceFilter(priceFilter.filter((price) => price !== priceValue));
    } else {
      setPriceFilter([priceValue]);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 300); 
  };

  const handleTransmissionFilterChange = (event) => {
    setIsLoading(true);
    const transmissionValue = event.target.value;
    if (transmissionFilter.includes(transmissionValue)) {
      setTransmissionFilter(transmissionFilter.filter((transmission) => transmission !== transmissionValue));
    } else {
      setTransmissionFilter([...transmissionFilter, transmissionValue]);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 300); 
  };

  const handleSupplierFilterChange = (event) => {
    setIsLoading(true);
    const supplierValue = event.target.value;
    if (supplierFilter.includes(supplierValue)) {
      setSupplierFilter(supplierFilter.filter((supplier) => supplier !== supplierValue));
    } else {
      setSupplierFilter([...supplierFilter, supplierValue]);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 300); 
  };

  const handleCategoryFilterChange = (event) => {
    setIsLoading(true);
    const categoryValue = event.target.value;
    if (categoryFilter.includes(categoryValue)) {
      setCategoryFilter(categoryFilter.filter((category) => category !== categoryValue));
    } else {
      setCategoryFilter([...categoryFilter, categoryValue]);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 300); 
  };

  const handleFuelFilterChange = (event) => {
    setIsLoading(true);
    const fuelValue = event.target.value;
    if (fuelFilter.includes(fuelValue)) {
      setFuelFilter(fuelFilter.filter((fuel) => fuel !== fuelValue));
    } else {
      setFuelFilter([...fuelFilter, fuelValue]);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 300); 
  }


  const filteredCars = carData.filter((car) => {
    if (priceFilter.length === 0) return true;
    const [minPrice, maxPrice] = priceFilter[0].split("-").map(Number);
    return car.price >= minPrice && car.price <= maxPrice;
  });
  

  const filteredCarsWithTransmission = filteredCars.filter((car) => {
    if (transmissionFilter.length === 0) return true;
    return transmissionFilter.includes(car.transmission);
  });

  const filteredCarsWithSupplier = filteredCarsWithTransmission.filter((car) => {
    if (supplierFilter.length === 0) return true;
    return supplierFilter.includes(car.supplier);
  });

  const filteredCarsWithFuel = filteredCarsWithSupplier.filter((car) => {
    if (fuelFilter.length === 0) return true;
    return fuelFilter.includes(car.fuelType);
  });

  const filteredCarsWithCategory = filteredCarsWithFuel.filter((car) => {
    if (categoryFilter.length === 0) return true;
    return categoryFilter.includes(car.category);
  });

 


  return (
    <div className="row">
      <Col lg="4">
        <Card>
          <CardBody>
            <FormGroup>
              <Label for="priceFilter">
                <strong>Preço por dia</strong>
              </Label>
              <div className="filter-options">
                <Label check>
                  <Input
                    type="checkbox"
                    value="0-5000"
                    checked={priceFilter.includes("0-5000")}
                    onChange={handlePriceFilterChange}
                  />{" "}
                  0 - 5000 $00
                </Label>
                <Label check>
                  <Input
                    type="checkbox"
                    value="5000-10000"
                    checked={priceFilter.includes("5000-10000")}
                    onChange={handlePriceFilterChange}
                  />{" "}
                  5000 - 10000 $00
                </Label>
                {/* Outras opções de preço */}
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="transmissionFilter">
                <strong>Transmissão</strong>
              </Label>
              <div className="filter-options">
                <Label check>
                  <Input
                    type="checkbox"
                    value="Manual"
                    checked={transmissionFilter.includes("Manual")}
                    onChange={handleTransmissionFilterChange}
                  />{" "}
                  Manual
                </Label>
                <Label check>
                  <Input
                    type="checkbox"
                    value="Automático"
                    checked={transmissionFilter.includes("Automático")}
                    onChange={handleTransmissionFilterChange}
                  />{" "}
                  Automático
                </Label>
                {/* Outras opções de transmissão */}
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="fuelFilter">
                <strong>Combustível</strong>
              </Label>
              <div className="filter-options">
                <Label check>
                  <Input
                    type="checkbox"
                    value="Gasolina"
                    checked={fuelFilter.includes("Gasolina")}
                    onChange={handleFuelFilterChange}
                  />{" "}
                  Gasolina
                </Label>
                <Label check>
                  <Input
                    type="checkbox"
                    value="Gasóleo"
                    checked={fuelFilter.includes("Gasóleo")}
                    onChange={handleFuelFilterChange}
                  />{" "}
                  Gasóleo
                </Label>
                {/* Outras opções de combustível */}
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="supplierFilter">
                <strong>Fornecedores</strong>
              </Label>
              <div className="filter-options">
                <Label check>
                  <Input
                    type="checkbox"
                    value="Best Rental"
                    checked={supplierFilter.includes("Best Rental")}
                    onChange={handleSupplierFilterChange}
                  />{" "}
                  Best Rental
                </Label>
                <Label check>
                  <Input
                    type="checkbox"
                    value="Fornecedor B"
                    checked={supplierFilter.includes("Fornecedor B")}
                    onChange={handleSupplierFilterChange}
                  />{" "}
                  Fornecedor B
                </Label>
                {/* Outras opções de fornecedores */}
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="categoryFilter">
                <strong>Categoria de carros</strong>
              </Label>
              <div className="filter-options">
                <Label check>
                  <Input
                    type="checkbox"
                    value="Sedan"
                    checked={categoryFilter.includes("Sedan")}
                    onChange={handleCategoryFilterChange}
                  />{" "}
                  Sedan
                </Label>
                <Label check>
                  <Input
                    type="checkbox"
                    value="SUV"
                    checked={categoryFilter.includes("SUV")}
                    onChange={handleCategoryFilterChange}
                  />{" "}
                  SUV
                </Label>
                <Label check>
                  <Input
                    type="checkbox"
                    value="Hatchback"
                    checked={categoryFilter.includes("Hatchback")}
                    onChange={handleCategoryFilterChange}
                  />{" "}
                  Hatchback
                </Label>
                <Label check>
                  <Input
                    type="checkbox"
                    value="Esportivo"
                    checked={categoryFilter.includes("Esportivo")}
                    onChange={handleCategoryFilterChange}
                  />{" "}
                  Esportivo
                </Label>
                <Label check>
                  <Input
                    type="checkbox"
                    value="Cupê"
                    checked={categoryFilter.includes("Cupê")}
                    onChange={handleCategoryFilterChange}
                  />{" "}
                  Cupê
                </Label>
                <Label check>
                  <Input
                    type="checkbox"
                    value="Minivan"
                    checked={categoryFilter.includes("Minivan")}
                    onChange={handleCategoryFilterChange}
                  />{" "}
                  Minivan
                </Label>
                <Label check>
                  <Input
                    type="checkbox"
                    value="Crossover"
                    checked={categoryFilter.includes("Crossover")}
                    onChange={handleCategoryFilterChange}
                  />{" "}
                  Crossover
                </Label>
                {/* Adicione outras categorias aqui */}
              </div>
            </FormGroup>

          </CardBody>
        </Card>
      </Col>
      <Col lg="8">
        <div className="row">
          {isLoading ? (
            <div className="loader">
              <LoaderContainer>
                <ClipLoader color="#001F3F" loading={isLoading} size={25} />
              </LoaderContainer>
            </div>
          ) : (
            <div className="row">
              {filteredCarsWithCategory.map((car) => (
                <CarItem key={car.id} item={car} />
              ))}
            </div>
          )}
        </div>
      </Col>

    </div>
  );
};

export default CarList;
