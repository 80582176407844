import React from "react";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/car-item.css";
import { GiCarDoor, GiGearStickPattern} from "react-icons/gi";
import { MdOutlineAirlineSeatReclineNormal } from "react-icons/md";
import { IoSnowOutline } from "react-icons/io5";
import { FaSuitcaseRolling } from "react-icons/fa";
import { BsFuelPump } from "react-icons/bs";
import rentalCompanyLogo from '../../assets/all-images/best_rental_logo2.jpg';


const CarItem = (props) => {
  const { id, imgUrl, transmission, carName, cargoCapacity, passenger, fuelType, price } = props.item;

  return (
    <Col lg="6" md="6" sm="6" className="mb-5">
      <div className="car__item">
        <div className="car__img">
          <img src={imgUrl} alt="" className="w-100" />
        </div>

        <div className="car__item-content mt-4">
        <img src={rentalCompanyLogo} alt="Rental Company Logo" className="car__item-logo" /> {/* Add the rental company logo here */}
          <h4 className="section__title text-center">{carName}</h4>
          <h6 className="rent__price text-center mt-">
            {price}$00 <span>/ Dia</span>
          </h6>

          <div className="car__item-info d-flex align-items-center justify-content-between mt-3 mb-4">
            <span className=" d-flex align-items-center gap-1">
             <GiCarDoor className="icon"/>{4} Portas
            </span>
            <span className=" d-flex align-items-center gap-1">
              <BsFuelPump className="icon"/> {fuelType}
            </span>
            <span className=" d-flex align-items-center gap-1">
              <FaSuitcaseRolling className="icon"/> {cargoCapacity} Malas
            </span>
           
          </div>
          <div className="car__item-info d-flex align-items-center justify-content-between mt-3 mb-4">
          
            <span className=" d-flex align-items-center gap-1">
            <MdOutlineAirlineSeatReclineNormal className="icon"/> {passenger} lugares
            </span>
            <span className=" d-flex align-items-center gap-1">
            <GiGearStickPattern className="icon"/> {transmission} 
            </span>
            <span className=" d-flex align-items-center gap-1">
              <IoSnowOutline className="icon"/> A/C
            </span>
          </div>
          
          <Link to={`/cars/${id}`}>
            <button className="w-100 car__item-btn car__btn-rent">
              Ver oferta
            </button>
          </Link>
      

          {/*<button className=" w-50 car__item-btn car__btn-details">
            <Link to={`/cars/${carName}`}>Detalhes</Link>
          </button>*/
          }
        </div>
      </div>
    </Col>
  );
};

export default CarItem;
