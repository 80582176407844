import React, {useState, useEffect} from "react";
import { useSelector } from 'react-redux';

import HeroSlider from "../components/UI/HeroSlider";
import Helmet from "../components/Helmet/Helmet";

import { Container, Row, Col } from "reactstrap";
import FindCarForm from "../components/UI/FindCarForm";
import AboutSection from "../components/UI/AboutSection";
//import CarItem from "../components/UI/CarItem";
import "../styles/loader.css"; 
import ScrollToTopArrow from '../components/UI/ScrollToTopArrow';
import Partners from "../components/UI/Partners";
import apiUtils from "../apiUtils"; 
import CarList from "../components/UI/CarList"; 
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';



/*import BecomeDriverSection from "../components/UI/BecomeDriverSection";
import Testimonial from "../components/UI/Testimonial";

import BlogList from "../components/UI/BlogList";
*/

const Home = () => {
  
  const [carListData, setCarListData] = useState([]);
  const [scrollToCarItem, setScrollToCarItem] = useState(false);
  const [loading, setLoading] = useState(false);

  const AVAILABLE_CARS_API = process.env.REACT_APP_AVAILABLE_CARS_API;


  useEffect(() => {
    if (scrollToCarItem) {
      const carItemElement = document.getElementById("car-item");
      if (carItemElement) {
        carItemElement.scrollIntoView({ behavior: "smooth" });
      }
      setScrollToCarItem(false);
    }
  }, [scrollToCarItem]);

  const handleSearchClick = async (queryString) => {
    try {
      setLoading(true);
  
      const response = await apiUtils.get(`${AVAILABLE_CARS_API}${queryString}`);
  
      const resposta = response.data;
      console.log(resposta);
      setCarListData(resposta.data);
      setScrollToCarItem(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const translations = useSelector(
    (state) => state.translation.translations[state.translation.selectedLanguage].home
  );

  const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  `;

 
  return (

    <Helmet title="Home">
      {/* ============= hero section =========== */}
      <section className="p-0 hero__slider-section">
       <HeroSlider />
       {/* <img src={slider1} alt="Hero" className="hero__image" />*/}


        <div className="hero__form">
          <Container>
            <Row className="form__row">
              <Col lg="4" md="4">
                <div className="find__cars-left">
                  <h3>{translations.findCarSubtitle}</h3>
                </div>
              </Col>

              <Col lg="8" md="8" sm="12">
                <FindCarForm  onSearchClick={handleSearchClick} />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      {/* =========== car offer section ============= */}
       {/* Renderizar o loader enquanto loading for true */}
       {loading ? (
            <div className="loader">
              <LoaderContainer>
                <ClipLoader color="#001F3F" loading={loading} size={25} />
              </LoaderContainer>
            </div>
       ) : (
       carListData.length > 0 && (
        <section id="car-item">
          <Container>
            <Row>
              <Col lg="12" className="text-center mb-5">
                <h6 className="section__subtitle">{translations.carOfferSubtitle}</h6>
                <h2 className="section__title">{translations.carOfferTitle}</h2>
              </Col>

             {/* {carListData.slice(0, 6).map((item) => (
              <CarItem item={item} key={item.id} />
              ))}
              */}
              
              <CarList carData={carListData} />

            </Row>
          </Container>
        </section>
       )
      )}
      {/* =========== about section ================ */}
      <AboutSection />

       {/* =========== Partner section =========== */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-4 text-center">
              <h6 className="section__subtitle">{translations.partnersSubtitle}</h6>
              <h2 className="section__title">{translations.partnersTitle}</h2>
            </Col>
            <Partners />
          </Row>
        </Container>
      </section>


  
      
      
      {/* ========== services section ============ 
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h6 className="section__subtitle">Veja os nossos</h6>
              <h2 className="section__title">Serviços Populares</h2>
            </Col>

            <ServicesList />
          </Row>
        </Container>
      </section>
      */}
      
      {/* =========== become a driver section ============ 
      <BecomeDriverSection />
      */}

      {/* =========== testimonial section =========== 
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-4 text-center">
              <h6 className="section__subtitle">Our clients says</h6>
              <h2 className="section__title">Testimonials</h2>
            </Col>

            <Testimonial />
          </Row>
        </Container>
      </section>
      */}

      {/* =============== blog section =========== 
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h6 className="section__subtitle">Explore our blogs</h6>
              <h2 className="section__title">Latest Blogs</h2>
            </Col>

            <BlogList />
          </Row>
        </Container>
      </section>
      */}
      <ScrollToTopArrow />
    </Helmet>
  );
};

export default Home;
