import React from 'react';
import { useSelector } from 'react-redux';
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import { Container} from "reactstrap";
import "../styles/cookie-policy.css"


const CookiePolicy = () => {

  const translations = useSelector(
    (state) => state.translation.translations[state.translation.selectedLanguage].cookiePolicy
  );

    return (
        <Helmet title="Política de Cookies">
        <CommonSection title={translations.title} />
        <Container>
  
        <div className="cookie-policy">

            <p>{translations.content.paragraph1}</p>
            <p>{translations.content.paragraph2}</p>
           
            <p>{translations.content.paragraph3}</p>

            <ul>
                <li>
                <strong>{translations.content.cookiesList.necessaryCookies.title}</strong> {translations.content.cookiesList.necessaryCookies.content}
                </li>
                <li>
                <strong>{translations.content.cookiesList.analyticalCookies.title}</strong> {translations.content.cookiesList.analyticalCookies.content}
                </li>
                <li>
                <strong>{translations.content.cookiesList.functionalityCookies.title}</strong> {translations.content.cookiesList.functionalityCookies.content}
                </li>
                <li>
                <strong>{translations.content.cookiesList.advertisingCookies.title}</strong> {translations.content.cookiesList.advertisingCookies.content}
                </li>
            </ul>

            <p>{translations.content.paragraph4}</p>
            </div>
        </Container>
      </Helmet>
    );
  };
  
  export default CookiePolicy;
