import React from 'react';
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import { Container} from "reactstrap";
import "../styles/liability-damage-policy.css"

const LiabilityDamagePolicy = () => {
  return (
    <Helmet title="Política de Responsabilidade e Danos">
    <CommonSection title="Política de Responsabilidade e Danos" />
    <Container>
    <div className="liability-damage-policy">
      <h3>Responsabilidade do Locatário</h3>
      <p>
        Ao alugar um veículo da CV Rental, o locatário assume total responsabilidade pelo veículo durante o período de locação. Isso inclui a responsabilidade por danos causados ao veículo, perda ou roubo do veículo e qualquer incidente que ocorra durante o uso do veículo.
      </p>
      <h3>Condição do Veículo</h3>
      <p>
        Ao receber o veículo, o locatário deve verificar minuciosamente seu estado e condição. Qualquer dano pré-existente deve ser relatado imediatamente à CV Rental. Caso contrário, o locatário poderá ser responsabilizado por danos não relatados.
      </p>
      <h3>Uso Adequado do Veículo</h3>
      <p>
        O locatário deve usar o veículo alugado de acordo com as leis de trânsito locais e respeitar todas as restrições e condições estabelecidas pela CV Rental. O veículo não deve ser usado para fins ilegais, corridas, condução sob influência de álcool ou drogas, ou qualquer outra atividade perigosa.
      </p>
      <h3>Danos e Reparos</h3>
      <p>
        Em caso de danos ao veículo durante o período de locação, o locatário deve relatar imediatamente à CV Rental e seguir as instruções fornecidas. O locatário será responsável por cobrir os custos de reparo, exceto nos casos em que o seguro contratado cubra os danos.
      </p>
      <h3>Seguro</h3>
      <p>
        A CV Rental oferece opções de seguro para cobertura de danos ou responsabilidade em caso de acidentes. A contratação do seguro é opcional, mas altamente recomendada. Caso o locatário opte por não contratar seguro, ele será totalmente responsável por quaisquer danos ao veículo.
      </p>
      <h3>Perda ou Roubo do Veículo</h3>
      <p>
        Em caso de perda ou roubo do veículo, o locatário deve relatar imediatamente às autoridades competentes e à CV Rental. O locatário será responsável por cobrir os custos associados à perda ou roubo, incluindo o valor total do veículo.
      </p>
      <h3>Indenização</h3>
      <p>
        O locatário concorda em indenizar a CV Rental por qualquer dano causado ao veículo durante o período de locação, incluindo danos diretos e indiretos, custos de reparo, perda de uso e desvalorização do veículo.
      </p>
      <p>
        É importante revisar cuidadosamente nossa Política de Responsabilidade e Danos antes de alugar um veículo connosco. Recomendamos ler atentamente os termos e condições do contrato de locação para compreender plenamente suas responsabilidades. Em caso de dúvidas ou esclarecimentos adicionais, entre em contato connosco.
      </p>
    </div>
    </Container>
    </Helmet>
  );
};

export default LiabilityDamagePolicy;
