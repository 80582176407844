import React from "react";
import {Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';
import Home from "../pages/Home";
import About from "../pages/About";
import Partners from "../pages/Partners";
import CarDetails from "../pages/CarDetails";
import Service from "../pages/Service";
import BlogDetails from "../pages/BlogDetails";
import NotFound from "../pages/NotFound";
import Contact from "../pages/Contact";
import ReservationPolicy from '../pages/ReservationReturnPolicy';
import CancellationRefundPolicy from '../pages/CancellationRefundPolicy';
import CookiePolicy from '../pages/CookiePolicy';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import LiabilityDamagePolicy from "../pages/LiabilityDamagePolicy";
import Login from '../pages/LoginForm';
import Register from '../pages/RegisterForm';
import ForgetPassword from '../pages/ForgetPassword';
import MyAccount from '../pages/MyAccount';


const Routers = () => {

  return (
    <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/cars/:slug" element={<CarDetails />} />
        <Route path="/service" element={<Service />} />
        <Route path="/blogs/:slug" element={<BlogDetails />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/reservation-policy" element={<ReservationPolicy />} />
        <Route path="/cancellation-refund-policy" element={<CancellationRefundPolicy/>} />
        <Route path="/liability-damage-policy" element={<LiabilityDamagePolicy />} />
        <Route path="/cookies-policy" element={<CookiePolicy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/login" element={<Login/>} />
        <Route path="/register" element={<Register/>} />
        <Route path="/forget-password" element={<ForgetPassword/>} />
        <Route element={<PrivateRoutes/>}>
          <Route path="/my-account" element={<MyAccount/>} exact></Route>
        </Route>
        <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Routers;
