import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
});

let isRefreshing = false;
let refreshSubscribers = [];

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb);
}

function onTokenRefreshed(token) {
  refreshSubscribers.map((cb) => cb(token));
  refreshSubscribers = [];
}

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry && !isRefreshing) {
      originalRequest._retry = true;

      isRefreshing = true;

      try {
        const refreshToken = localStorage.getItem('token');
        const refreshResponse = await api.post('/refresh-token', { refreshToken });
        const newToken = refreshResponse.data.token;

        localStorage.setItem('token', newToken);

        onTokenRefreshed(newToken);

        originalRequest.headers.Authorization = `Bearer ${newToken}`;

        return api(originalRequest);
        
      } catch (refreshError) {
        console.error('Falha ao renovar o token:', refreshError);
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

const apiUtils = {
  get: (url) => api.get(url),
  post: (url, data) => api.post(url, data),
  put: (url, data) => api.put(url, data),
  delete: (url) => api.delete(url),
  login: (values) => api.post('/login', values),
  subscribeTokenRefresh: subscribeTokenRefresh,
  onTokenRefreshed: onTokenRefreshed,
};

export default apiUtils;
