import { useEffect } from "react";
import { useSelector } from "react-redux";
import pluginConfig from './CookieConsentConfig';
import 'vanilla-cookieconsent';
import './cookie-consent.css';

export default function CookieConsent() {
    
const selectedLanguage = useSelector(state => state.translation.selectedLanguage);


    useEffect(() => {
        if (!document.getElementById('cc--main')) {
            window.CC = window.initCookieConsent();

            const updatedConfig = {
                ...pluginConfig,
                current_lang: selectedLanguage || 'pt', // Defina o idioma selecionado ou 'pt' como padrão
            };

            window.CC.run(updatedConfig);
        }
    }, [selectedLanguage]);

    return null;
}

