import { createSlice } from '@reduxjs/toolkit';
import ptTranslations from '../locales/pt.json';
import enTranslations from '../locales/en.json';
import Cookies from 'js-cookie';

const getInitialLanguage = () => {
  const languageCookie = Cookies.get('selectedLanguage');
   console.log(languageCookie);
  return languageCookie || 'pt'; // Use 'pt' como idioma padrão se o cookie não estiver definido
};

const initialState = {
  selectedLanguage: getInitialLanguage(),
  translations: {
    pt: ptTranslations,
    en: enTranslations,
  },
};

const translationSlice = createSlice({
  name: 'translation',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
    },
    setTranslations: (state, action) => {
      state.translations = action.payload;
    }
  }
});

export const { setLanguage, setTranslations } = translationSlice.actions;

export default translationSlice.reducer;
