import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Card, CardImg, CardBody, CardTitle } from 'reactstrap';
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import rentalCompanyLogo from '../assets/all-images/best_rental_logo2.jpg';
import '../styles/partners.css';


const Partners = () => {
  // Dados das companhias de rent a car
  const companies = [
    { name: 'Best Rental', logo: rentalCompanyLogo },
    { name: 'Best Rental', logo: rentalCompanyLogo },
    { name: 'Best Rental', logo: rentalCompanyLogo },
    // Adicione mais empresas conforme necessário
  ];

  const translations = useSelector(
    (state) => state.translation.translations[state.translation.selectedLanguage].partners
  );

  return (
    
    <Helmet title="Partners">
    <CommonSection title={translations.title} />
    <Container>
    <div className="intro-text">
        <h2 className="intro-heading">{translations.introHeading}</h2>
        <p className="intro-paragraph">{translations.introParagraph}</p>
    </div>
    <Row>
        {companies.map((company, index) => (
          <Col xs={12} sm={6} md={4} key={index}>
            <Card className="parceiros-card">
              <div className="parceiros-img-container">
                <CardImg src={company.logo} alt={company.name} className="parceiros-img" />
              </div>
              <CardBody>
                <CardTitle tag="h5" className="parceiros-title">{company.name}</CardTitle>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
    </Helmet>
  );
};

export default Partners;
