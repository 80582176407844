import React, { useState, useRef, useEffect } from "react";


import { Container} from "reactstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "../../styles/header.css";
import { Dropdown } from 'react-bootstrap';
import apiUtils from '../../apiUtils';
import { useSelector, useDispatch } from 'react-redux';
import { setLoggedOut } from '../../redux/authSlice';
import { logoutFacebookUser } from '../../redux/facebookSlice';
import { logoutGoogleUser } from '../../redux/googleSlice';
import { clearLoginUser } from '../../redux/loginSlice';
import { setLanguage } from '../../redux/translationSlice';
import { persistor } from '../../redux/store';
import userDefaultImg from '../../assets/all-images/default_user_image.svg';
import { MdOutlineKeyboardArrowDown } from "react-icons/md";



const Header = () => {
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const facebookUserData = useSelector(state => state.facebook.user);
  const googleUserData = useSelector(state => state.google.user);
  const cvRentalUserData = useSelector(state => state.login.user);
  const language = useSelector(state => state.translation.selectedLanguage);
  const translations = useSelector(
    (state) => state.translation.translations[state.translation.selectedLanguage].header
  );
  



  const DEFAULT_USER_IMAGE = userDefaultImg;


  let userData;

  if (facebookUserData && facebookUserData.provider === 'facebook') {
    userData = facebookUserData;
  } else if (googleUserData && googleUserData.provider === 'google') {
    userData = googleUserData;
  }else if(cvRentalUserData && cvRentalUserData.provider === 'cvrental') {
    userData = cvRentalUserData;
  }

  const userImage = userData && userData.picture ? userData.picture : DEFAULT_USER_IMAGE


  const toggleMenu = () => menuRef.current.classList.toggle("menu__active");

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    
  
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }, []);
  
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };

    const handleLogout = async () => {
      try {
        // Realize uma chamada para a API para invalidar o token
        await apiUtils.post('/logout'); // Substitua "/logout" pela rota correta da sua API
        localStorage.removeItem('token'); // Ou remova o cookie

        // Outras ações de limpeza podem ser executadas, como redefinir o estado global
        dispatch(setLoggedOut());
        dispatch(logoutFacebookUser()); 
        dispatch(logoutGoogleUser()); 
        dispatch(clearLoginUser()); 
        persistor.purge();
        navigate('/login'); // Redirecione para a página de login ou outra página apropriada
      } catch (error) {
        console.log('Erro ao fazer logout:', error);
        // Lide com o erro adequadamente
      }
    };

    const changeLanguage = (selectedLanguage) => {
      dispatch(setLanguage(selectedLanguage));
    };

   
  

    const navLinks = [
      {
        path: '/home',
        display: translations.navLinks.home,
      },
      {
        path: '/about',
        display: translations.navLinks.about,
      },
      {
        path: '/service',
        display: translations.navLinks.services,
      },
      {
        path: '/partners',
        display: translations.navLinks.partners,
      },
      {
        path: '/contact',
        display: translations.navLinks.contact,
      },
    ];
    
  


  return (
    <header className="header">
      {/* ============ header top ============ 
      <div className="header__top">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="6">
              <div className="header__top__left">
                <span>Precisa de Ajuda?</span>
                <span className="header__top__help">
                  <i className="ri-phone-fill"></i> +238 5825090
                </span>
              </div>
            </Col>

            <Col lg="6" md="6" sm="6">
              <div className="header__top__right d-flex align-items-center justify-content-end gap-3">
              <Link to="#" className=" d-flex align-items-center gap-1" onClick={() => setShowModal(true)}>
                <i className="ri-login-circle-line"></i> Entrar
              </Link>

              <Link to="#" className=" d-flex align-items-center gap-1" onClick={() => { setShowModal(true); setIsLogin(false) }}>
                <i className="ri-user-line"></i> Registar
              </Link>

              <Modal size="sm" show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>{isLogin ? 'Login' : 'Register'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {isLogin ? (
                    <LoginForm />
                  ) : (
                    <RegisterForm />
                  )}
                </Modal.Body>
              </Modal>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      */}

      {/* =============== header middle =========== 
      <div className="header__middle">
        <Container>
          <Row>
            <Col lg="4" md="3" sm="4">
              <div className="logo">
                <h1>
                  <Link to="/home" className=" d-flex align-items-center gap-2">
                    <i className="ri-car-line"></i>
                    <span>
                      CV Rental
                    </span>
                  </Link>
                </h1>
              </div>
            </Col>

            <Col lg="3" md="3" sm="4">
              <div className="header__location d-flex align-items-center gap-2">
                <span>
                  <i className="ri-earth-line"></i>
                </span>
                <div className="header__location-content">
                  <h4>Cabo Verde</h4>
                  <h6>Praia</h6>
                </div>
              </div>
            </Col>

            <Col lg="3" md="3" sm="4">
              <div className="header__location d-flex align-items-center gap-2">
                <span>
                  <i className="ri-time-line"></i>
                </span>
                <div className="header__location-content">
                  <h4>Segunda a Sexta</h4>
                  <h6>10am - 7pm</h6>
                </div>
              </div>
            </Col>

            <Col
              lg="2"
              md="3"
              sm="0"
              className=" d-flex align-items-center justify-content-end "
            >
              <button className="header__btn btn ">
                <Link to="/contact">
                  <i className="ri-phone-line"></i> Entrar em Contato
                </Link>
              </button>
            </Col>
          </Row>
        </Container>
      </div>

      */}

      {/* ========== main navigation =========== */}

      <div className="main__navbar">
        <Container>
          <div className="navigation__wrapper d-flex align-items-center justify-content-between">
            <span className="mobile__menu">
              <i className="ri-menu-line" onClick={toggleMenu}></i>
            </span>

            <div className="logo">
              <h1>
                <Link to="/home" className="d-flex align-items-center gap-2">
                  <i className="ri-car-line"></i>
                  <span>CV Rental</span>
                </Link>
              </h1>
            </div>

            <div className="navigation" ref={menuRef} onClick={toggleMenu}>
              <div className="menu">
                {navLinks.map((item, index) => (
                  <NavLink
                    to={item.path}
                    className={(navClass) =>
                      navClass.isActive ? "nav__active nav__item" : "nav__item"
                    }
                    key={index}
                  >
                    {item.display}
                  </NavLink>
                ))}
              </div>
            </div>

            <div className="d-flex align-items-center gap-3">
            <Dropdown className="d-inline">
              <Dropdown.Toggle id="languageDropdown">
                {language === "pt" ? (
                  <>
                  <span className="language-label language-label-pt">PT</span>
                  <img
                    src="https://cdn.countryflags.com/thumbs/cape-verde/flag-round-250.png"
                    alt="Cabo Verde"
                    className="flag-icon"
                  />
                  </>
                ) : (
                  <>
                  <span className="language-label language-label-en">EN</span>
                  <img
                    src="https://cdn.countryflags.com/thumbs/united-kingdom/flag-round-250.png"
                    alt="United Kingdom"
                    className="flag-icon"
                  />
                  </>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu className="banner-dropdown-menu">
                {language !== "pt" && (
                  <Dropdown.Item onClick={() => changeLanguage("pt")}>
                    <img
                      src="https://cdn.countryflags.com/thumbs/cape-verde/flag-round-250.png"
                      alt="Cabo Verde"
                      className="flag-icon"
                    />{" "}
                    Português(CV)
                  </Dropdown.Item>
                )}
                {language !== "en" && (
                  <Dropdown.Item onClick={() => changeLanguage("en")}>
                    <img
                      src="https://cdn.countryflags.com/thumbs/united-kingdom/flag-round-250.png"
                      alt="United Kingdom"
                      className="flag-icon"
                    />{" "}
                    English
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>

          <div className="header__top__right d-flex align-items-center justify-content-end gap-3">
             { /*<button className="button button--register">
                <Link to="/booking" className="booking-account-link"> <i className="ri-user-line"></i> Gerir a Reserva</Link>
                  </button>*/}
              {isLoggedIn ? (
              <Dropdown
                    className="d-inline mx-2"
                    show={isOpen}
                    onClick={toggleDropdown}
                    ref={dropdownRef}
                  >

                    <div className="avatar-container">
                      <img src={userImage} className="avatar" alt="Avatar" />
                      <span className="name">{userData && userData.name}</span>
                      <MdOutlineKeyboardArrowDown/>
                    </div>

                    <Dropdown.Menu>
                      <Dropdown.Item as={Link} to="/my-account"><i className="ri-account-circle-line"></i> {translations.dropdown.account}</Dropdown.Item>
                      <Dropdown.Item onClick={handleLogout}><i className="ri-logout-circle-line"></i> {translations.dropdown.logout}</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ): (
                
                  <button className="button button--login">
                  <Link to="/login" className="create-account-link">
                    <i className="ri-login-circle-line"></i> {translations.loginButton}
                  </Link>
                </button>
              )}
              </div> 
          </div>
        </div>
        </Container>
      </div>
    </header>
  );
};

export default Header;
 