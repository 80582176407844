import React, { useState } from 'react';
import { Form, FormGroup, Button, Label, Input} from 'reactstrap';
import DatePicker from 'react-datepicker';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/personal-data.css';
import { ptBR } from 'date-fns/locale';


const PersonalData = () => {
  const submitHandler = (event) => {
    console.log('Formulário submetido');
    event.preventDefault();
  };

  const [phone, setPhone] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  
  const currentDate = new Date();
  const previousDate = new Date();
  previousDate.setDate(currentDate.getDate() - 1);


  return (
    <Form onSubmit={submitHandler} className="form-personal-data">
      <FormGroup floating className="mb-4">
      <Input
        id="email"
        name="email"
        placeholder="Email"
        type="email"
        disabled
      />
      <Label for="email">
        Email
      </Label>
    </FormGroup>

      <FormGroup floating className="mb-4">
        <Input type="text"  id="nome"  name="nome" placeholder="nome"/>
        <Label for="nome">
        Nome
        </Label>
      </FormGroup>

    
      <FormGroup  className="personaldata__form mb-4">
      <PhoneInput
        defaultCountry="cv"
        value={phone}
        onChange={(phone) => setPhone(phone)}
        id="Telemovel"
        name="Telemovel"
      />
      </FormGroup>

      <FormGroup floating className="mb-4">
        <Input type="text"  id="Morada"  name="Morada" placeholder="Morada"/>
        <Label for="Morada">
        Morada
        </Label>
      </FormGroup>

      <FormGroup floating className=" mb-4">
        <select  defaultValue="" di id='Sexo' name='Sexo' className='form-control'>
          <option value="">
            Selecione o Sexo
          </option>
          <option value="Masculino">Masculino</option>
          <option value="Feminino">Feminino</option>
        </select>
        <Label for="Sexo">
        Sexo
        </Label>
      </FormGroup>
      

      <FormGroup className="personaldata__form  mb-4">   
      <DatePicker
        placeholderText="Data de Nascimento"
        dateFormat="dd/MM/yyyy"
        selected={selectedDate}
        onChange={(date) => setSelectedDate(date)}
        maxDate={previousDate}
        locale={ptBR}
      />
      </FormGroup>

      

      <Button className="btn btn-update" type="submit">
        <i className="me-2 ri-refresh-line"></i> Atualizar
      </Button>
    </Form>
  );
};

export default PersonalData;
