import React from 'react';
import { Container, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import apiUtils from '../apiUtils'; 
import toast, { Toaster } from 'react-hot-toast';
import '../styles/login-form.css';

const ForgetPassword = () => {
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Email inválido').required('O campo de email é obrigatório')
    }),
    onSubmit: async values => {
      try {
        // Enviar solicitação para o servidor para redefinir a senha
        const response = await apiUtils.post('/reset-password', values);

        // Tratar a resposta do servidor
        console.log(response.data); // Mensagem de sucesso ou outras informações retornadas pelo servidor

        // Redirecionar para a página de confirmação ou exibir uma mensagem de sucesso ao usuário
        // Exemplo:
        toast.success('Um link de redefinição de senha foi enviado para o seu email.', {
          duration: 5000,
          position: 'top-center'},
          );

      } catch (error) {
        // Tratar erros e exibir uma mensagem de erro ao usuário
        console.log(error.response.data); // Mensagem de erro retornada pelo servidor

        // Exibir uma mensagem de erro ao usuário, por exemplo:
        toast.error('Ocorreu um erro ao solicitar a redefinição de senha. Por favor, tente novamente.', {
          duration: 5000,
          position: 'top-center'},
          );
      }
    }
  });

  return (
    <Container>
      <Row>
        <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
          <div className="card border-0 rounded-3 my-5">
            <div className="card-body p-4 p-sm-5">
              <Toaster containerStyle={{
                  top: 80
                }}/> 
              <h3 className="card-title text-center mb-4 fw-bold">Esqueceu sua Password?</h3>
              <h5 className="card-title text-center mb-4 fw-light fs-5">
                Digite o endereço de e-mail associado à sua conta e enviaremos um link para redefinir sua password.
              </h5>
              <form onSubmit={formik.handleSubmit}>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className={`form-control form-control-sm ${
                      formik.touched.email && formik.errors.email ? 'is-invalid' : ''
                    }`}
                    id="floatingInput"
                    placeholder="name@example.com"
                    {...formik.getFieldProps('email')}
                  />
                  <label htmlFor="floatingInput">Email</label>
                  {formik.touched.email && formik.errors.email && (
                    <div className="invalid-feedback">{formik.errors.email}</div>
                  )}
                </div>
                <div className="d-grid">
                  <button className="btn btn-login" type="submit">
                    <i className="me-2 ri-login-circle-line"></i> Solicitar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default ForgetPassword;
