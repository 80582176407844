const serviceData = [
  {
    id: 1,
    title: "Aluguer de Longa Duração para Empresas",
    icon: "ri-community-line",
    desc: "Oferecemos um serviço de aluguer de longa duração exclusivo para empresas e instituições, além das opções de aluguer convencionais. Esta modalidade proporciona vantagens significativas em termos de mobilidade, liberdade e tranquilidade, a um preço mais acessível.",
  },

  {
    id: 2,
    title: "Passeio Completo pela ilha",
    icon: "ri-map-pin-2-line",
    desc: "Descubra todos os encantos e atrações da ilha no nosso emocionante passeio pela ilha. Acompanhado por guias especializados, irá visitar os principais pontos turísticos, conhecer a história e a cultura local e ter uma experiência inesquecível explorando todos os cantos da ilha.",
  },

  {
    id: 3,
    title: "Aluguer com Quilometragem Ilimitada",
    icon: "ri-roadster-line",
    desc: "Explore a cidade sem limites com nosso serviço de aluguer de carros com quilometragem ilimitada. Desfrute da liberdade de dirigir o quanto quiser, sem se preocupar com custos adicionais por quilometragem. Vá aonde seu coração mandar e aproveite ao máximo sua experiência de aluguer de carro.",
  },

  {
    id: 4,
    title: "Reserva Rápida e Fácil",
    icon: "ri-timer-flash-line",
    desc: "Faça a sua reserva de carro de forma rápida e fácil com o nosso sistema de reserva online. Com apenas alguns cliques, pode selecionar o veículo desejado, escolher as datas e horários de levantamento e devolução, e garantir a sua reserva em questão de minutos. ",
  },

  {
    id: 5,
    title: "Muitos Locais de Retirada",
    icon: "ri-map-pin-line",
    desc: "Oferecemos uma ampla variedade de locais de recolha para sua conveniência. Escolha o local mais próximo de si, seja no aeroporto, no centro da cidade ou em outras áreas estratégicas. Com vários pontos de recolha disponíveis, pode começar a sua viagem com facilidade e conveniência.",
  },

  {
    id: 6,
    title: "Transferência do Aeroporto",
    icon: "ri-flight-takeoff-line",
    desc: "Comece a sua viagem com tranquilidade com o nosso serviço de transfer do aeroporto. Os nossos motoristas profissionais estarão à sua espera no aeroporto, prontos para o levar ao seu destino. Evite o stress e a confusão dos transportes públicos ou a procura por táxis. Garanta uma chegada suave e sem complicações com o nosso serviço de transferência confiável.",
  },
];

export default serviceData;
