import React, { useState } from 'react';
import { Button, Badge } from 'reactstrap';
import { RiEdit2Line, RiCloseLine, RiArrowDownSLine, RiArrowUpSLine, RiArrowLeftLine, RiArrowRightLine } from 'react-icons/ri';
import ReactPaginate from 'react-paginate';
import '../../styles/reservation-list.css'; // Arquivo CSS para o componente

const reservationsPerPage = 5; // Número de reservas por página

const reservations = [
  {
    id: 1,
    date: "2023-06-25",
    status: "Confirmado",
    statusCode: "CONF",
    carModel: "Tesla Model S",
    pickupLocation: "Airport",
    returnLocation: "City Center",
    totalPrice: "$200",
    clientName: "John Doe",
    duration: "3 dias",
    reservationNumber: "RES12345",
    vehicleType: "Carro",
    passengers: 4,
    pricePerDay: "$50",
    discountCode: "DISCOUNT123",
    paymentStatus: "Pago",
  },
  {
    id: 2,
    date: "2023-06-27",
    status: "Pendente",
    statusCode: "PEND",
    carModel: "BMW X5",
    pickupLocation: "Downtown",
    returnLocation: "Hotel",
    totalPrice: "$150",
    clientName: "John Doe",
    duration: "3 dias",
    reservationNumber: "RES12345",
    vehicleType: "Carro",
    passengers: 4,
    pricePerDay: "$50",
    discountCode: "DISCOUNT123",
    paymentStatus: "Pago",
  },
  {
    id: 3,
    date: "2023-06-29",
    status: "Cancelado",
    statusCode: "CANC",
    carModel: "Mercedes-Benz C-Class",
    pickupLocation: "Suburb",
    returnLocation: "Airport",
    totalPrice: "$180",
    clientName: "John Doe",
    duration: "3 dias",
    reservationNumber: "RES12345",
    vehicleType: "Carro",
    passengers: 4,
    pricePerDay: "$50",
    discountCode: "DISCOUNT123",
    paymentStatus: "Pago",
  }
];


const ReservationList = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [expandedItem, setExpandedItem] = useState(null);

  const handleItemClick = (index) => {
    setExpandedItem(index === expandedItem ? null : index);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'CONF':
        return 'success';
      case 'PEND':
        return 'warning';
      case 'CANC':
        return 'danger';
      default:
        return 'primary';
    }
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
    setExpandedItem(null); // Fechar qualquer item expandido ao trocar de página
  };

  const offset = currentPage * reservationsPerPage;
  const paginatedReservations = reservations.slice(offset, offset + reservationsPerPage);

  return (
    <div>
      <ul className="reservation-list">
        {paginatedReservations.map((reservation, index) => (
          <li
            key={offset + index}
            className={`reservation-item ${expandedItem === offset + index ? 'expanded' : ''}`}
            onClick={() => handleItemClick(offset + index)}
          >
            <div className="reservation-summary">
              <div className="expand-icon">
                {expandedItem === offset + index ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
              </div>
              <h3>{reservation.reservationNumber}</h3>
              <h3>{reservation.date}</h3>
              <h3>{reservation.clientName}</h3>
              <h3>{reservation.vehicleType}</h3>
              <div>
              <Badge color={`${getStatusColor(reservation.statusCode)}`} pill >
              {reservation.status}
              </Badge>
            </div>
            </div>
            {expandedItem === offset + index && (
              <div className="reservation-details">
                <p>Modelo do Carro: {reservation.carModel}</p>
                <p>Local de Retirada: {reservation.pickupLocation}</p>
                <p>Local de Devolução: {reservation.returnLocation}</p>
                <p>Preço Total: {reservation.totalPrice}</p>
                <div className="button-group">
                  <Button className="btn btn-alter mr-2">
                    <RiEdit2Line className="mr-1" /> Alterar
                  </Button>
                  <Button className="btn btn-cancel">
                    <RiCloseLine className="mr-1" /> Cancelar
                  </Button>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
      <ReactPaginate
       previousLabel={
        <>
          <RiArrowLeftLine /> Anterior
        </>
      }
      nextLabel={
        <>
          Próximo <RiArrowRightLine />
        </>
      }
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Math.ceil(reservations.length / reservationsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />
    </div>
  );
};

export default ReservationList;
