/**
 * @type {UserConfig}
 */
import ReactGA from 'react-ga';


const pluginConfig = {
    current_lang: 'pt',
    autoclear_cookies: true, // default: false
    page_scripts: true, // default: false
  
    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
     hide_from_bots: true,                  // default: false
    // remove_cookie_tables: false             // default: false
     cookie_name: 'cr_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0
  
    onFirstAction: function (user_preferences, cookie) {
      // callback triggered only once
      const analyticsEnabled = window.CC.allowedCategory('analytics');
      console.log(`analytics ${analyticsEnabled ? 'enabled' : 'disabled'}`);
      if (analyticsEnabled) {
        // Habilitar o código de rastreamento do Google Analytics
        ReactGA.initialize('G-V1WG1DN1YT'); // Substitua pelo seu ID de acompanhamento do Google Analytics
        ReactGA.pageview(window.location.pathname);
      }
    },
  
    onAccept: function (cookie) {
      // ...
      localStorage.setItem('cookieConsent', 'true'); // Armazene a preferência do usuário

    },
  
    onChange: function (cookie, changed_preferences) {
      // ...
    },
    
  
    languages: {
        pt: {
          consent_modal: {
            title: 'Utilizamos cookies!',
            description:
              'Olá, este site utiliza cookies essenciais para garantir o seu funcionamento adequado e cookies de rastreamento para entender como interage com ele. Estes últimos só serão definidos após o consentimento. <button type="button" data-cc="c-settings" class="cc-link">Deixe-me escolher</button>.',
            primary_btn: {
              text: 'Aceitar todos',
              role: 'accept_all',
            },
            secondary_btn: {
              text: 'Rejeitar todos',
              role: 'accept_necessary',
            },
          },
          settings_modal: {
            title: 'Configurações de cookies',
            save_settings_btn: 'Salvar configurações',
            accept_all_btn: 'Aceitar todos',
            reject_all_btn: 'Rejeitar todos',
            close_btn_label: 'Fechar',
            cookie_table_headers: [
              { col1: 'Nome' },
              { col2: 'Domínio' },
              { col3: 'Expiração' },
              { col4: 'Descrição' },
            ],
            blocks: [
              {
                title: 'Uso de cookies 📢',
                description:
                  'Utilizamos cookies para garantir as funcionalidades básicas do site e melhorar sua experiência online. Pode optar por cada categoria quando quiser. Para mais detalhes sobre cookies e outros dados sensíveis, leia a <a href="/cookies-policy" class="cc-link">política de cookies</a> completa.',
              },
              {
                title: 'Cookies estritamente necessários',
                description:
                  'Esses cookies são essenciais para o funcionamento adequado do site. Sem eles, o site não funcionaria corretamente.',
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true,
                },
              },
              {
                title: 'Cookies de desempenho e análises',
                description:
                  'Estes cookies permitem que o site se lembre das escolhas que fez anteriormente.',
                toggle: {
                  value: 'analytics',
                  enabled: false,
                  readonly: false,
                },
                cookie_table: [
                  {
                    col1: '^_ga',
                    col2: 'google.com',
                    col3: '2 anos',
                    col4: 'descrição ...',
                    is_regex: true,
                  },
                  {
                    col1: '_gid',
                    col2: 'google.com',
                    col3: '1 dia',
                    col4: 'descrição ...',
                  },
                ],
              },
              {
                title: 'Cookies de publicidade e segmentação',
                description:
                  'Estes cookies recolhem informações sobre a forma como utiliza o website, as páginas que visitou e os links que clicou. Todos os dados são anonimizados e não podem ser utilizados para o identificar.',
                toggle: {
                  value: 'targeting',
                  enabled: false,
                  readonly: false,
                },
              },
              {
                title: 'Mais informações',
                description:
                  'Para qualquer dúvida em relação à nossa política de cookies e às suas opções, entre em <a class="cc-link" href="/contact">contacto connosco</a>.',
              },
            ],
          },
        },
        en: {
          consent_modal: {
            title: 'We use cookies!',
            description:
              'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
            primary_btn: {
              text: 'Accept all',
              role: 'accept_all', // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: 'Reject all',
              role: 'accept_necessary', // 'settings' or 'accept_necessary'
            },
          },
          settings_modal: {
            title: 'Cookie Settings',
            save_settings_btn: 'Save settings',
            accept_all_btn: 'Accept all',
            reject_all_btn: 'Reject all',
            close_btn_label: 'Close',
            cookie_table_headers: [
              { col1: 'Name' },
              { col2: 'Domain' },
              { col3: 'Expiration' },
              { col4: 'Description' },
            ],
            blocks: [
              {
                title: 'Cookie usage 📢',
                description:
                  'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="#" class="cc-link">privacy policy</a>.',
              },
              {
                title: 'Strictly necessary cookies',
                description:
                  'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                },
              },
              {
                title: 'Performance and Analytics cookies',
                description:
                  'These cookies allow the website to remember the choices you have made in the past',
                toggle: {
                  value: 'analytics', // your cookie category
                  enabled: false,
                  readonly: false,
                },
                cookie_table: [
                  // list of all expected cookies
                  {
                    col1: '^_ga', // match all cookies starting with "_ga"
                    col2: 'google.com',
                    col3: '2 years',
                    col4: 'description ...',
                    is_regex: true,
                  },
                  {
                    col1: '_gid',
                    col2: 'google.com',
                    col3: '1 day',
                    col4: 'description ...',
                  },
                ],
              },
              {
                title: 'Advertisement and Targeting cookies',
                description:
                  'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                toggle: {
                  value: 'targeting',
                  enabled: false,
                  readonly: false,
                },
              },
              {
                title: 'More information',
                description:
                  'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#yourcontactpage">contact us</a>.',
              },
            ],
          },
        },
      },
      gui_options: {
        consent_modal: {
            layout: 'bar',               // box/cloud/bar
            position: 'bottom right',     // bottom/middle/top + left/right/center
            transition: 'slide',           // zoom/slide
            swap_buttons: false            // enable to invert buttons
        },
        settings_modal: {
            layout: 'box',                 // box/bar
            position: 'right',              // left/right
            transition: 'slide'            // zoom/slide
        }
    }
    };
  
  export default pluginConfig;
  