import React from 'react';
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import { Container} from "reactstrap";
import "../styles/cancellation-refund-policy.css"

const CancellationRefundPolicy = () => {
  return (
    <Helmet title="Política de Cancelamento e Reembolso">
    <CommonSection title="Política de Cancelamento e Reembolso" />
    <Container>
    <div className="cancellation-refund-policy">
      <h3>Cancelamento de Reservas</h3>
      <p>
        Se você precisar cancelar sua reserva, entre em contato connosco o mais breve possível. O cancelamento está sujeito às seguintes condições:
      </p>
      <p>
        a) Cancelamento com antecedência: Se você cancelar sua reserva com uma antecedência mínima de 48 horas antes do horário de retirada programado, faremos o reembolso total do valor pago, deduzindo eventuais taxas de processamento.
      </p>
      <p>
        b) Cancelamento tardio: Cancelamentos feitos com menos de 48 horas de antecedência em relação ao horário de retirada programado estão sujeitos a uma taxa de cancelamento. A taxa de cancelamento será determinada de acordo com as circunstâncias específicas, como a duração da reserva e a disponibilidade do veículo.
      </p>
      <h3>Reembolsos</h3>
      <p>
        Os reembolsos serão processados de acordo com a forma de pagamento originalmente utilizada durante a reserva. O tempo necessário para que o reembolso seja creditado em sua conta pode variar dependendo do método de pagamento e das políticas da instituição financeira.
      </p>
      <h3>Alterações de Reservas</h3>
      <p>
        Se você precisar fazer alterações em sua reserva, como alterar as datas de retirada e devolução, entre em contato connosco com antecedência. Faremos o possível para atender às suas solicitações, sujeito à disponibilidade de veículos.
      </p>
      <p>
        Não comparecimento: Em caso de não comparecimento no horário de retirada programado sem aviso prévio, a reserva será considerada como "não utilizada" e não haverá reembolso.
      </p>
      <h3>Alterações ou Cancelamentos por parte da CV Rental</h3>
      <p>
        Reservamo-nos o direito de fazer alterações ou cancelar reservas em circunstâncias excepcionais, como problemas técnicos, questões de segurança ou indisponibilidade de veículos. Nesses casos, faremos o possível para oferecer uma alternativa adequada ou fornecer um reembolso integral.
      </p>
      <p>
        É importante ler atentamente e compreender nossa Política de Cancelamento e Reembolso antes de fazer uma reserva connosco. Essa política visa estabelecer condições justas para ambas as partes envolvidas. Em caso de dúvidas ou necessidade de mais informações, entre em contato connosco para obter esclarecimentos adicionais.
      </p>
    </div>
    </Container>
    </Helmet>
  );
};

export default CancellationRefundPolicy;
