import React from "react";
import { Container, Row } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import ServicesList from "../components/UI/ServicesList";
import { useSelector } from 'react-redux';


const Service = () => {
  
  const translations = useSelector(
    (state) => state.translation.translations[state.translation.selectedLanguage].services
  );

  return (
    <Helmet title="Serviços">
      <CommonSection title={translations.title} />
      <section>
        <Container>
          <Row>
            <ServicesList />
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Service;
