import React, {useEffect} from "react";
import { Col } from "reactstrap";
import "../../styles/services-list.css";
import servicesData from "../../assets/data/serviceData";
import ScrollReveal from 'scrollreveal';
import { useSelector } from 'react-redux';



const ServicesList = () => {

  const translations = useSelector(
    (state) => state.translation.translations[state.translation.selectedLanguage].services
  );

  useEffect(() => {
    const sr = ScrollReveal();
    const animateServiceItem = () => {
      sr.reveal('.animates', {
        duration: 1000, // Animation duration in milliseconds
        origin: 'bottom', // Animation starting point (e.g., 'top', 'bottom', 'left', 'right')
        distance: '20px', // Distance to move during animation
        delay: 200, // Delay before animation starts in milliseconds
        easing: 'ease-in-out', // Easing effect (refer to previous response for options)
      });
    };
    animateServiceItem();
  }, []);

  return (
    <>
      {servicesData.map((item) => (
        <ServiceItem item={item} key={item.id} translations={translations} />
      ))}
    </>
  );
};

const ServiceItem = ({ item, translations  }) => {
  if (!translations) {
    return null;
  }

  return (
    <Col lg="4" md="4" sm="6" className="mb-3">
      <div className="animates service__item">
        <span className="mb-3 d-inline-block">
          <i className={item.icon} />
        </span>

        <h6>{translations[`title${item.id}`]}</h6>
        <p className="animates section__description">{translations[`desc${item.id}`]}</p>
      </div>
    </Col>
  );
};

export default ServicesList;
