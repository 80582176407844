import React, { useState }  from "react";
import {  useSelector } from 'react-redux';

import "../../styles/find-car-form.css";
import { Form, FormGroup } from "reactstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ptBR } from 'date-fns/locale';





const FindCarForm = ({ onSearchClick }) => {
  const [pickupDateTime, setPickupDateTime] = useState(new Date());
  const [returnDateTime, setReturnDateTime] = useState(() => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 7);
    return currentDate;
  });



  const handlePickupDateChange = (date) => {
    setPickupDateTime(date);
    // If the return date has not been set or is earlier than the pickup date,
    // update the return date to the pickup date + 1 day
    if (!returnDateTime || returnDateTime < date) {
      const nextDay = new Date(date);
      nextDay.setDate(nextDay.getDate() + 1);
      setReturnDateTime(nextDay);
    }
  };

  const handleReturnDateChange = (date) => {
    if (date <= pickupDateTime) {
      const nextDay = new Date(pickupDateTime);
      nextDay.setDate(nextDay.getDate() + 1);
      setReturnDateTime(nextDay);
    } else {
      setReturnDateTime(date);
    }
  };


  const availableHours = [];
  const currentHour = new Date().getHours();
  for (let i = currentHour + 1; i < 24; i++) {
    availableHours.push(i);
  }

  const handleSearchClick = async () => {
    const startDate = pickupDateTime.toISOString().split("T")[0].split("-").reverse().join("-");
    const startTime = pickupDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const endDate = returnDateTime.toISOString().split("T")[0].split("-").reverse().join("-");
    const endTime = returnDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    const queryString = `startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}&startTime=${startTime}&endTime=${endTime}`;

      try {
        onSearchClick(queryString);

      } catch (error) {
        console.error(error);
      }
  }

  const getFormattedTime = (dateTime) => {
    if (dateTime) {
      return dateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
    return '';
  };

  const translations = useSelector(
    (state) => state.translation.translations[state.translation.selectedLanguage].findCarForm
  );


  

  return (
    <Form className="form">
      <div className=" d-flex align-items-center justify-content-between flex-wrap">
        <FormGroup className="form__group">
          <div className="date-picker">
            <label htmlFor="pickup-date">{translations.pickupDateLabel}</label>
            <DatePicker
              id="pickup-date"
              selected={pickupDateTime}
              onChange={handlePickupDateChange}
              dateFormat="dd/MM/yyyy"
              locale={ptBR}
              placeholderText="Selecione uma data"
              autoComplete="off"
              minDate={new Date()}
            />
          </div>
        </FormGroup>

        <FormGroup className="form__group">
        <div className="time-picker">
          <label htmlFor="pickup-time">{translations.pickupTimeLabel}</label>
          <select
              id="pickup-time"
              value={getFormattedTime(pickupDateTime)}
              onChange={(e) => {
                const timeStr = e.target.value;
                const [hours, minutes] = timeStr.split(':').map(str => parseInt(str));
                const newDateTime = pickupDateTime ? new Date(pickupDateTime) : new Date();
                newDateTime.setHours(hours);
                newDateTime.setMinutes(minutes);
                setPickupDateTime(newDateTime);
              }}
            >
              {[...Array(24)].map((_, index) => {
                const hours = index.toString().padStart(2, '0');
                const timeStr = `${hours}:00`;
                return (
                  <option key={index} value={timeStr}>
                    {timeStr}
                  </option>
                );
              })}
              </select>
        </div>
        </FormGroup>

        <FormGroup className="form__group">
        <div className="date-picker">
          <label htmlFor="return-date">{translations.returnDateLabel}</label>
          <DatePicker
            id="return-date"
            selected={returnDateTime}
            onChange={handleReturnDateChange}
            dateFormat="dd/MM/yyyy"
            locale={ptBR}
            placeholderText="Selecione uma data"
            autoComplete="off"
            minDate={pickupDateTime || new Date()}
            excludeDates={[pickupDateTime]}
          />
        </div>
        </FormGroup>

        <FormGroup className="form__group">
        <div className="time-picker">
          <label htmlFor="return-time">{translations.returnTimeLabel}</label>
          <select
              id="return-time"
              value={getFormattedTime(returnDateTime)}
              onChange={(e) => {
                const timeStr = e.target.value;
                const [hours, minutes] = timeStr.split(':').map(str => parseInt(str));
                const newDateTime = new Date(returnDateTime);
                newDateTime.setHours(hours);
                newDateTime.setMinutes(minutes);
                setReturnDateTime(newDateTime);
              }}
            >
              {[...Array(24)].map((_, index) => {
                const hours = index.toString().padStart(2, '0');
                const timeStr = `${hours}:00`;
                return (
                  <option key={index} value={timeStr}>
                    {timeStr}
                  </option>
                );
              })}
            </select>
            </div>
        </FormGroup>

        <FormGroup className="form__group">
          <button type="button" className="btn find__car-btn" onClick={handleSearchClick}>{translations.searchButtonLabel}</button>
        </FormGroup>
      </div>
    </Form>
  );
};

export default FindCarForm;
