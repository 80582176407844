import React, { useState } from "react";
import { Nav, Tab, Container, Row, Col } from "react-bootstrap";
import PersonalData from "../components/UI/PersonalData";
import {useSelector } from 'react-redux';
import userDefaultImg from '../assets/all-images/default_user_image.svg';
import { AiOutlineIdcard, AiOutlineCalendar } from "react-icons/ai";
import "../styles/my-account.css";
import ReservationList from "../components/UI/ReservationList";




function MyAccount() {
  const [activeTab, setActiveTab] = useState("account");

  
  const facebookUserData = useSelector(state => state.facebook.user);
  const googleUserData = useSelector(state => state.google.user);
  const cvRentalUserData = useSelector(state => state.login.user);
  const DEFAULT_USER_IMAGE = userDefaultImg;



  let userData;

  if (facebookUserData && facebookUserData.provider === 'facebook') {
    userData = facebookUserData;
  } else if (googleUserData && googleUserData.provider === 'google') {
    userData = googleUserData;
  }else if(cvRentalUserData && cvRentalUserData.provider === 'cvrental') {
    userData = cvRentalUserData;
  }

  const userImage = userData && userData.picture ? userData.picture : DEFAULT_USER_IMAGE;


  return (
    <>
    <Container>
        <div className="bg-white rounded-lg d-block d-sm-flex mb-4">
          <div className="profile-tab-nav border-right">
            <div className="p-4">
              <div className="img-circle text-center">
                <img src={userImage} alt="avatar" className="" />
              </div>
              <h5 className="text-center mt-2">Olá {userData.name}!</h5>
            </div>
            <Nav className="flex-column nav-pills" activeKey={activeTab}>
              <Nav.Item>
                <Nav.Link
                  eventKey="account"
                  onClick={() => setActiveTab("account")}
                >
                  <AiOutlineIdcard style={{ fontSize: '20px' , marginRight:'5px' }}/>Dados Pessoais
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="booking"
                  onClick={() => setActiveTab("booking")}
                >
                   <AiOutlineCalendar style={{ fontSize: '20px' , marginRight:'5px' }}/>Minhas Reservas
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="tab-content p-4 p-md-5">
            <Tab.Container activeKey={activeTab} >
              <Row>
                <Col>
                  <Tab.Content >
                    <Tab.Pane eventKey="account">
                      <h3 className="mb-4">Meus Dados Pessoais</h3>
                      <PersonalData/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="booking">
                      <h3 className="mb-4">As Minhas Reservas</h3>
                      <ReservationList/>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
        </Container>
        </>
  );
}

export default MyAccount;
