import React from 'react';
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import { Container} from "reactstrap";
import "../styles/reservation-return-policy.css"

const ReservationReturnPolicy = () => {
  return (
    <Helmet title="Política de Reservas e Devolução">
    <CommonSection title="Política de Reservas e Devolução" />
    <Container>
    <div className="reservation-return-policy">
    <h2 className="reservation-text">Reservas</h2>
      <p>
        <strong>Processo de Reserva:</strong> A CV Rental oferece um processo de reserva fácil e conveniente. Ao fazer uma reserva em nosso site, você concorda em fornecer informações precisas e completas, incluindo datas de retirada e devolução, local de retirada, informações pessoais e detalhes de pagamento.
      </p>
      <p>
        <strong>Disponibilidade de Veículos:</strong> Todas as reservas estão sujeitas à disponibilidade de veículos. Faremos o possível para atender às suas preferências de veículo, mas não podemos garantir modelos específicos. Caso o veículo escolhido não esteja disponível, faremos o possível para oferecer uma alternativa adequada.
      </p>
      <p>
        <strong>Confirmação da Reserva:</strong> Após a conclusão da reserva, você receberá um e-mail de confirmação com os detalhes da reserva, incluindo informações de contato, termos e condições, e instruções adicionais. É importante verificar todas as informações e entrar em contato connosco imediatamente em caso de discrepâncias.
      </p>
      <p>
        <strong>Pagamento:</strong> O pagamento integral do aluguer do veículo deve ser feito no momento da reserva, a menos que especificado de outra forma. Aceitamos diversos métodos de pagamento seguros, como cartões vinti4, cartões de crédito e transferências bancárias. Todas as transações são processadas de forma segura e confidencial.
      </p>
      
      <h2 className="devolution-text">Devolução</h2>
      <p>
        <strong>Horário de Devolução:</strong> O veículo deve ser devolvido no horário acordado durante o processo de reserva. Caso precise estender o período de aluguer, entre em contato connosco com antecedência para verificar a disponibilidade e fazer os arranjos necessários. Atrasos na devolução podem estar sujeitos a taxas adicionais.
      </p>
      <p>
        <strong>Condição do Veículo:</strong> O veículo deve ser devolvido nas mesmas condições em que foi retirado, levando em consideração o desgaste normal do uso. É responsabilidade do locatário inspecionar o veículo no momento da retirada e notificar qualquer dano ou problema imediatamente. Quaisquer danos adicionais ou multas serão de responsabilidade do locatário.
      </p>
      <p>
        <strong>Combustível:</strong> O veículo deve ser devolvido com a mesma quantidade de combustível que foi fornecida no momento da retirada. Caso contrário, uma taxa de reabastecimento será aplicada.
      </p>
      <p>
        <strong>Taxas de Devolução Antecipada:</strong> Em caso de devolução antecipada do veículo, não haverá reembolso para o período não utilizado, a menos que especificado de outra forma nos termos e condições da reserva.
      </p>
      <p>
        <strong>Cancelamento:</strong> Caso precise cancelar sua reserva, consulte nossos termos e condições para obter informações sobre <a href="/cancellation-refund-policy">políticas de cancelamento e reembolso</a> aplicáveis.
      </p>
      <p>
        Esta Política de Reservas e Devolução visa fornecer diretrizes claras e justas para nossos clientes. Ao fazer uma reserva connosco, você concorda em cumprir todas as condições e termos estabelecidos. Em caso de dúvidas ou problemas, entre em contato connosco para obter assistência.
      </p>
    </div>
    </Container>
    </Helmet>
  );
};

export default ReservationReturnPolicy;
